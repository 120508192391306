import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const router = createRouter({
  // history: createWebHashHistory(),  // hash 模式
  history: createWebHistory(),  // history 模式
  routes: [
    {
      path: '/',
      name: 'layout',
      component: () => import(`@/layout/Layout.vue`),
      children: [
        {
          path: '/',
          name: 'Home',
          component: () => import(`@/views/home/index.vue`),
          meta: {
            title: '学科资源网',
          },
        },
        {
          path: '/ce-special',
          name: 'CE-Special',
          component: () => import(`@/views/home/ce-special.vue`),
          meta: {
            title: '专题',
          },
        },
        {
          path: '/book-special',
          name: 'Book-Special',
          component: () => import(`@/views/book/special.vue`),
          meta: {
            title: '专题',
          },
        },
        {
          path: '/book-special-list',
          name: 'Book-Special-List',
          component: () => import(`@/views/book/specialList.vue`),
          meta: {
            title: '书城',
          },
        },
        {
          path: '/book-special-detail',
          name: 'Book-Special-Detail',
          component: () => import(`@/views/book/specialDetail.vue`),
          meta: {
            title: '书城',
          },
        },
        {
          path: '/channel',
          name: 'Channel',
          component: () => import(`@/views/home/channel.vue`),
          meta: {
            title: '频道',
          },
        },
        {
          path: '/list',
          name: 'List',
          component: () => import(`@/views/home/list.vue`),
          meta: {
            title: '列表',
          },
        },
        {
          path: '/detail-cover',
          name: 'DetailCover',
          component: () => import(`@/views/home/detail-cover.vue`),
          meta: {
            title: '详情合集',
          },
        },
        {
          path: '/detail',
          name: 'Detail',
          component: () => import(`@/views/home/detail.vue`),
          meta: {
            title: '详情',
          },
        },
        {
          path: '/resource',
          name: 'Resource',
          component: () => import(`@/views/home/resource.vue`),
          meta: {
            title: '资源蓝',
          },
        },
        {
          path: '/schools',
          name: 'Schools',
          component: () => import(`@/views/home/schools.vue`),
          meta: {
            title: '名校',
          },
        },
        {
          path: '/school-detail',
          name: 'SchoolDetail',
          component: () => import(`@/views/home/school-detail.vue`),
          meta: {
            title: '名校详情',
          },
        },
        {
          path: '/posts',
          name: 'Posts',
          component: () => import(`@/views/home/posts.vue`),
          meta: {
            title: '教师频道',
          },
        },
        {
          path: '/post',
          name: 'post',
          component: () => import(`@/views/home/post.vue`),
          meta: {
            title: '教师频道详情',
          },
        },
        {
          path: '/downloaded',
          name: 'Downloaded',
          component: () => import(`@/views/home/downloaded.vue`),
          meta: {
            title: '下载',
          },
        },
      ]
    },
    {
      path: '/',
      name: 'SupportLayout',
      component: () => import('@/layout/supportLayout.vue'),
      children: [
        {
          path: '/support',
          name: 'Support',
          component: () => import(`@/views/home/support.vue`),
          meta: {
            title: '帮助中心',
          },
        },
        {
          path: '/support-detail',
          name: 'SupportDetail',
          component: () => import(`@/views/home/supportDetail.vue`),
          meta: {
            title: '帮助',
          },
        },
      ]
    },
    {
      path: '/',
      name: 'MissionLayout',
      component: () => import('@/layout/missionLayout.vue'),
      children: [
        {
          path: '/missions',
          name: 'Missions',
          component: () => import(`@/views/home/missions.vue`),
          meta: {
            title: '任务中心',
          },
        },
        {
          path: '/missions-list',
          name: 'missionslist',
          component: () => import(`@/views/home/missionslist.vue`),
          meta: {
            title: '任务大厅',
          },
        },
        {
          path: '/missions-recruit',
          name: 'missionsrecruit',
          component: () => import(`@/views/home/missionsrecruit.vue`),
          meta: {
            title: '兼职招募',
          },
        },
        {
          path: '/missions-position-detail',
          name: 'mpd',
          component: () => import(`@/views/home/missionspd.vue`),
          meta: {
            title: '任务详情',
          },
        },
        {
          path: '/missions-position',
          name: 'missionsposition',
          component: () => import(`@/views/home/missionsposition.vue`),
          meta: {
            title: '申请记录',
          },
        },
      ]
    },
    {
      path: '/',
      name: 'booksLayout',
      component: () => import('@/layout/bookLayout.vue'),
      children: [
        {
          path: '/books',
          name: 'Books',
          component: () => import(`@/views/book/index.vue`),
          meta: {
            title: '书城',
          },
        },
        {
          path: '/book-resource',
          name: 'Book-Resource',
          component: () => import(`@/views/book/resource.vue`),
          meta: {
            title: '书城',
          },
        },
        {
          path: '/book-agency',
          name: 'Book-Agency',
          component: () => import(`@/views/book/agency.vue`),
          meta: {
            title: '书城',
          },
        },
        {
          path: '/book-recruit',
          name: 'Book-Recruit',
          component: () => import(`@/views/book/recruit.vue`),
          meta: {
            title: '书城',
          },
        },
      ]
    },
    {
      path: '/',
      name: 'agencyLayout',
      component: () => import('@/layout/agencyLayout.vue'),
      children: [
        {
          path: '/agency',
          name: 'Agency',
          component: () => import(`@/views/agency/index.vue`),
          meta: {
            title: '机构',
          },
        },
        {
          path: '/agency-resource',
          name: 'agency-Resource',
          component: () => import(`@/views/agency/resource.vue`),
          meta: {
            title: '机构-资源列表',
          },
        },
        {
          path: '/agency-cover',
          name: 'agency-cover',
          component: () => import(`@/views/agency/cover.vue`),
          meta: {
            title: '机构-合集详情',
          }
        }
      ]
    },
    {
      path: '/',
      name: 'userLayout',
      component: () => import(`@/layout/userLayout.vue`),
      children: [
        {
          path: '/user',
          name: 'User',
          component: () => import(`@/views/user/index.vue`),
          meta: {
            title: '用户中心',
          },
        },
        {
          path: '/userInfo',
          name: 'userInfo',
          component: () => import(`@/views/user/userInfo.vue`),
          meta: {
            title: '个人信息',
          },
        },
        {
          path: '/collection',
          name: 'collection',
          component: () => import(`@/views/user/collection.vue`),
          meta: {
            title: '我的收藏',
          },
        },
        {
          path: '/binding',
          name: 'binding',
          component: () => import(`@/views/user/binding.vue`),
          meta: {
            title: '帐号绑定',
          },
        },
        {
          path: '/accountVerify',
          name: 'accountVerify',
          component: () => import(`@/views/user/accountVerify.vue`),
          meta: {
            title: '帐号安全',
          },
        },
        {
          path: '/assets',
          name: 'assets',
          component: () => import(`@/views/user/assets.vue`),
          meta: {
            title: '我的资产',
          },
        },
        {
          path: '/reward',
          name: 'reward',
          component: () => import(`@/views/user/reward.vue`),
          meta: {
            title: '我的奖金',
          },
        },
        {
          path: '/menberCenter',
          name: 'menberCenter',
          component: () => import(`@/views/user/menberCenter.vue`),
          meta: {
            title: '会员中心',
          },
        },
        {
          path: '/user-resource',
          name: 'userResource',
          component: () => import(`@/views/user/resource.vue`),
          meta: {
            title: '资源管理',
          },
        },
        {
          path: '/download',
          name: 'download',
          component: () => import(`@/views/user/download.vue`),
          meta: {
            title: '我的下载',
          },
        },
        {
          path: '/history',
          name: 'history',
          component: () => import(`@/views/user/history.vue`),
          meta: {
            title: '浏览记录',
          },
        },
        {
          path: '/followers',
          name: 'followers',
          component: () => import(`@/views/user/followers.vue`),
          meta: {
            title: '我的关注',
          },
        },
        {
          path: '/message',
          name: 'message',
          component: () => import(`@/views/user/message.vue`),
          meta: {
            title: '消息记录',
          },
        },
        {
          path: '/message-detail',
          name: 'messageDetail',
          component: () => import(`@/views/user/messageDetail.vue`),
          meta: {
            title: '消息详情',
          },
        },
        {
          path: '/feedback',
          name: 'feedback',
          component: () => import(`@/views/user/feedback.vue`),
          meta: {
            title: '留言反馈',
          },
        },
        {
          path: '/angency',
          name: 'angency',
          component: () => import(`@/views/user/angency.vue`),
          meta: {
            title: '我的机构',
          },
        },
        {
          path: '/task',
          name: 'task',
          component: () => import(`@/views/user/task.vue`),
          meta: {
            title: '我的任务',
          },
        },
        {
          path: '/add-book',
          name: 'addBook',
          component: () => import(`@/views/user/addBook.vue`),
          meta: {
            title: '创建书籍',
          },
        },
        {
          path: '/add-studio',
          name: 'addStudio',
          component: () => import(`@/views/user/addStudio.vue`),
          meta: {
            title: '创建工作室',
          },
        }

      ]
    },
    {
      path: '/',
      name: 'siteLayout',
      component: () => import('@/layout/Layout.vue'),
      children: [
        {
          path: '/site-map',
          name: 'siteMap',
          component: () => import(`@/views/siteMap/index.vue`),
          meta: {
            title: '网站导航',
          },
        },
      ]
    },
    {
      path: '/school-services',
      name: 'schoolServices',
      component: () => import(`@/views/schoolServices/index.vue`),
      meta: {
        title: '校网通',
      },
    },
    {
      path: '/',
      name: 'officialWebsite',
      // component: () => import('@/layout/Layout.vue'),
      children: [
        {
          path: '/official-website',
          name: 'officialWebsite',
          component: () => import(`@/views/officialWebsite/index.vue`),
          meta: {
            title: '企业站',
          },
        },
        {
          path: '/official-website/about',
          name: 'officialWebsiteAbout',
          component: () => import(`@/views/officialWebsite/about.vue`),
          meta: {
            title: '企业站',
          },
        },
        {
          path: '/official-website/news',
          name: 'officialWebsiteNews',
          component: () => import(`@/views/officialWebsite/news.vue`),
          meta: {
            title: '企业站',
          },
        },
        {
          path: '/official-website/news-detail',
          name: 'officialWebsiteNewsDetail',
          component: () => import(`@/views/officialWebsite/news-detail.vue`),
          meta: {
            title: '企业站',
          },
        },
        {
          path: '/official-website/join',
          name: 'officialWebsiteJoin',
          component: () => import(`@/views/officialWebsite/join.vue`),
          meta: {
            title: '企业站',
          },
        },
        {
          path: '/official-website/job-detail',
          name: 'officialWebsiteJobDetail',
          component: () => import(`@/views/officialWebsite/job-detail.vue`),
          meta: {
            title: '企业站',
          },
        },
        {
          path: '/official-website/job-apply',
          name: 'officialWebsiteJobApply',
          component: () => import(`@/views/officialWebsite/job-apply.vue`),
          meta: {
            title: '企业站',
          },
        },
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(`@/views/user/login.vue`),
      meta: {
        title: '登录',
      },
    },

    {
      path: '/*',
      redirect: '/',
    },
  ]
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title}`;
  }
  next()
})

router.afterEach((to, from) => {
})

export default router
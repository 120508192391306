import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'

// 引入路由
import router from './router'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { setupUI, modal } from './ui'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axios from '@/utils/http/axios'
// 注册路由
const app = createApp(App)
// main.js:注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// 注册路由
app.use(router)
app.use(ElementPlus, {
    locale: zhCn
});
setupUI(app)
// app.config.globalProperties.$http = axios
app.config.globalProperties.$modal = modal
app.config.globalProperties.$uploadUrl = axios.defaults.baseURL + 'user/resource/uploadFile'
app.component('QuillEditor', QuillEditor)

// 定义特性标志
window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

app.mount('#app')
<template>
  <router-view />
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  document.body.style.setProperty('--el-color-primary', '#17b777');
  document.body.style.setProperty('--el-color-primary-light-9', '#F5FBF0');
  document.body.style.setProperty('--el-color-primary-light-3', '#95d475');
});
const debounce = (fn, delay) => {
  let timer = null;

  return function () {
    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
ol,
ul,
li,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

#app {
  min-width: 1440px;
}
a {
  text-decoration: none;
}
.flex-display {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-items {
  display: flex;
  align-items: center;
}

.flex-items-plus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-end-plus {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-column-plus {
  display: flex;
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-row-plus {
  display: flex;
  flex-direction: row;
}

.flex-sp-around {
  justify-content: space-around;
}

.flex-sp-between {
  justify-content: space-between;
}

.text-align {
  text-align: center;
}

.flex-wrap-1 {
  display: flex;
  flex-wrap: wrap;
}

.flex-nowrap-1 {
  display: flex;
  flex-wrap: nowrap;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.align-sp-between {
  align-content: space-between;
}
</style>

import axios from 'axios'
import router from '@/router'
import {
  getToken, removeToken
} from './auth'

const baseURL = (process.env.NODE_ENV === 'production') ? 'https://api.xuekedu.com/api/' : '/api/'
const service = axios.create({
  baseURL,
  timeout: 30000 // request timeout
})
import { ElMessage } from 'element-plus';

export const token = getToken()

// request interceptor
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.config.responseType === 'blob') {
      const disposition = response.headers['content-disposition'];
      const matchArray = disposition.match(/filename="(.*)"/);
      const filename = decodeURI(matchArray[1])
      response.data.filename = filename
      return response.data
    }
    if (res.code == 401) {
      removeToken()
      router.push({ path: '/login', query: { type: 'qrcode' } })
    } else if (res.code == 0) {
      return res
    } else if (res.code == 1) {
      ElMessage({
        message: res.msg,
        type: 'error',
      });

      return Promise.reject(res)
    }
  },
  error => {

    if (error.response.status == 401) {
      removeToken()
      router.push({ path: '/login', query: { type: 'qrcode' } })
    }
    ElMessage({
      message: error.response.data.msg,
      type: 'error',
    });
    return Promise.reject(error)
  }
)

export default service
